// see src/stylesheets/sites/_common.scss
$product-category-header-bg: inherit; // product category header bg color
$product-category-active-filters-bg: inherit; // product category active filters bg color
$product-category-groups-filters-bg: inherit; // product category filter groups bg color
$product-category-rounded: false; // apply a rounded border to product category sections

// override default colors
$dark: #000000;
$warning: #d4c251;
$success: #477d60;
$danger: #733049;

// override main menu colors
$menu-bg: #f2f2f2;
$menu-dropdown-bg: $menu-bg;

$menu-sm-bg: $menu-bg; // mobile main menu header bg color
$menu-sm-fg: $dark; // mobile main menu header fg color

// override Campaign outlet button color
$outlet-btn-color: #f49f5c;

// override breadcrumbs colors (fg|display-home MUST be present)
$breadcrumb-fg: inherit; // use `inherit` for default color
$breadcrumb-display-home: inherit; // use `none` to hide home icon, default to `inherit`
// $breadcrumb-bg: inherit; // breadcrumb bg color
// $breadcrumb-divider-color: inherit; // breadcrumb divider item color
// $breadcrumb-active-color: inherit; // breadcrumb active item fg color

// override Campaign buygift button color
// $buygift-btn-color: $dark;

// override route links color
$link-color: #f49f5c;

$footer: $menu-bg;
$footer-brand: #1d1c1c;
.footer-links-column {
  border-right: 1px solid #f8f9fa36 !important;
}

$product-series-summary-bg: $menu-bg;
$product-series-summary-fg: $dark;
$product-series-button-bg: $outlet-btn-color;

// override product subcategory color
$product-subcategory-bg: #e7e4e4;
$product-subcategory-fg: $dark;

.dropdown-menu {
  border-radius: none;
}

.navbar-brand {
  padding: 0.8rem;
}

// override product discount ribbon color
$discount-ribbon-bg: #f49f5c;
$discount-ribbon-fg: $white;

.valentines {
  background-color: #df7899 !important;
}

.greybox {
  background-color: #eeeeee;
}

.monthly {
  background-color: #f49f5c !important;
}

.blackbox {
  text-align: center;
  color: #ffffff important;
  background-color: #000000;
  height: 40px;
  padding: 10px 0px 10px 0px;
  margin: 0px 10px 10px 10px;
}

.greenbox {
  background-color: #e7eae9;
}
