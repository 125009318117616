.#{shared-var("ShoppingCartItemBS")} {
  &-thumbnail {
    img {
      @extend .d-block;
      @extend .mx-auto;

      // fallback for older browsers
      min-width: 100% !important;
      max-width: 100% !important;
      max-height: 100%;

      // modern browser support
      max-width: stretch !important;
      //max-height: stretch;

      object-fit: contain !important;
    }
  }

  &-quantity {
    .input-group-prepend,
    .input-group-append {
      cursor: default !important;

      & > .input-group-text {
        &.disabled {
          @extend .text-muted;
        }
        &.calculating {
          cursor: wait !important;
          background-color: $gray-200 !important;
        }
        &:not(.disabled, .calculating) {
          @extend .bg-white;
          @extend .cursor-pointer;
        }
      }
    }
  }

  &-name {
    .nav-link {
      @extend .pl-0;
    }
  }

  &-price,
  &-amount,
  &-quantity {
    input,
    span {
      &.calculating {
        text-shadow: 0px 0px 3px;
      }
    }
  }
}
