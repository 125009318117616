.#{shared-var("CampaignBS")} {
  @extend .mx-auto;
  @extend .px-0;
  @extend .position-relative;

  // left: -88px;
  top: -1rem;
  @extend .w-100;
  // width: $desktop-width;
  // max-width: $desktop-width !important;

  &-header {
    font-size: 0.8rem;
    @extend .pt-4;
    @extend .text-center;
    &-title {
      @extend .font-weight-bolder;
    }
    &-text {
    }
  }

  &-buttons {
    @extend .my-3;

    button {
      @extend .h-100;
      @extend .text-uppercase;
      @extend .font-weight-bolder;
    }

    @include media-breakpoint-up(md) {
      height: 4rem;
      button {
        font-size: 1.4rem;
      }
    }
  }

  &-carousel {
    height: 510px;
    @include media-breakpoint-down(sm) {
      height: 344px;
    }
    overflow: hidden;
    &.carousel-item {
      .loading {
        div:not([role="status"]) {
          @extend .w-100;

          @include media-breakpoint-up(sm) {
            height: 292px !important;
          }
          @include media-breakpoint-up(md) {
            height: 371px !important;
          }
          @include media-breakpoint-up(lg) {
            height: 645px !important;
          }
        }
      }

      img {
        @extend .w-100;
        @extend .h-100;
        min-height: unset !important;
      }
    }
  }

  &-bottom-image {
    @extend .px-0;

    @include media-breakpoint-up(md) {
      &:not(:first-child) {
        padding-left: $spacer * 0.25 !important;
      }
      &:not(:last-child) {
        padding-right: $spacer * 0.25 !important;
      }
    }

    a:hover {
      @extend .text-decoration-none;
    }

    a > div.nav-link {
      opacity: unset;
    }

    &:last-child {
      img {
        height: 100% !important;
        width: 100% !important;
      }
    }

    img {
      height: 300px;

      @include media-breakpoint-down(sm) {
        width: 100% !important;
      }
    }
  }

  &-bottom-images {
    max-width: 1550px !important;
    @extend .px-0;
  }

  .footer {
    @extend .py-2;
    font-weight: 500;
    background-color: #f2f2f2 !important;
    font-size: 16px;
  }
}
