.#{shared-var("ProductSliderBS")} {
  @extend .px-0;
  //@extend .px-sm-3;

  &-title {
    @extend .text-center;
    @extend .font-weight-bold;
  }

  &-slider {
    height: auto !important;

    .carousel-inner {
      @extend .px-2;
      z-index: 1;
    }

    &-group-item {
      //@extend .mx-auto;
      @extend .px-2;

      @include media-breakpoint-up(xl) {
        &.col-xl-2 {
          flex: 0 0 20%;
          max-width: 20%;
        }
      }
    }

    $carousel-control-width: 32px;

    .carousel-control-prev {
      //left: -14px;
      left: 0;
      div.rounded {
        border-radius: $carousel-control-width 0 0 $carousel-control-width !important;
        svg {
          transform: translate(-0.25rem, 0);
        }
      }
    }

    .carousel-control-next {
      //right: -14px;
      right: 0;
      div.rounded {
        border-radius: 0 $carousel-control-width $carousel-control-width 0 !important;
        svg {
          transform: translate(-0.5rem, 0);
        }
      }
    }

    .carousel-control-prev,
    .carousel-control-next {
      color: unset !important;

      opacity: 1 !important;

      div.rounded {
        &:hover {
          opacity: 0.5 !important;
        }

        width: $carousel-control-width !important;

        z-index: inherit;
        background-color: $menu-bg !important;
      }
    }
  }
}
