.#{shared-var("FooterLinksBS")} {
  @extend .my-4;

  & * {
    @extend .border-0;
  }

  &-column {
    line-height: 1.2rem;
    // border-right: 1px solid $light !important;
  }

  &-item {
    @extend .m-1;
  }

  &-title {
    @extend .px-1;
    @extend .my-3;

    h2 {
      @extend .text-nowrap;
      @extend .font-weight-bold;
      font-size: inherit;
    }
  }

  .col-md-3 {
    width: auto !important;
    padding: 0.5em;
  }

  .list-group-item {
    @extend .p-1;
  }

  .list-group-item-action {
    color: black !important;
    &:hover {
      color: black !important;
      font-weight: 500;
    }
  }

  a:hover {
    @extend .text-decoration-none;
  }

  .contact-brand {
    @extend .mt-3;
  }
}
