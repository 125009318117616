.#{shared-var("CheckoutRelatedProductsBS")} {
  .carousel {
    &-inner {
      .carousel-item {
        img.card-img-top {
          @include media-breakpoint-up(md) {
            height: 160px !important;
          }
          @include media-breakpoint-down(sm) {
            height: 120px !important;
          }
        }

        .group-item {
          @extend .p-1;
        }

        .product-category-item-summary,
        .product-price-actual {
          @extend .text-left;
        }
      }
    }
  }
}
