.#{shared-var(ProductCategoryComparatorItemBS)} {
  @extend .justify-content-between;
  @extend .h-100;

  .card-img-top {
    @extend .mx-auto;
    //@extend .h-100;
    @extend .d-block;
    @extend .w-100;

    max-width: fit-content;
    max-width: 100%;
    max-height: fit-content;
    object-fit: contain;
  }

  .#{shared-var("ProductRatingBS")}-rating {
    text-align: center;
    text-align: unset;
  }

  .#{shared-var("ProductPriceBS")} {
    &-actual {
      @extend .px-3;
    }
  }

  &-popover {
    @extend .position-sticky;
    @extend .bg-light;
    @extend .shadow-lg;
    left: 0;
    bottom: 0;
    z-index: $zindex-sticky;

    & > .row {
      & > div {
        &:not(:last-child) {
          @extend .border-right;
          // @extend .border-secondary;
        }
        @extend .px-1;
      }
    }

    .#{shared-var(ProductCategoryComparatorItemBS)} {
      & > .container {
        @extend .px-1;

        & > .row {
          & > .col {
            @extend .px-2;

            .#{shared-var(ProductCategoryComparatorItemBS)}-summary {
              @extend .px-1;
            }

            .#{shared-var(ProductBS)}-price {
              @extend .px-1;
            }
          }
        }
      }
      &-title {
        font-weight: 600 !important;
        font-size: 1rem !important;
      }
    }

    .#{shared-var(ProductBS)}-price-actual {
      @extend .h5;
      @extend .text-dark;
    }
  }
}
