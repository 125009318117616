.#{shared-var("ProductStockStatusBS")} {
  &.out-of-stock,
  &.available,
  &.limited {
    &::before {
      @extend .d-inline-block;
      @extend .mr-1;
      content: "●";
    }
  }
  &.out-of-stock::before {
    @extend .text-warning;
  }

  &.available::before {
    @extend .text-success;
  }

  &.limited::before {
    @extend .text-warning;
  }
}
